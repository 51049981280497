/* eslint-disable react-hooks/exhaustive-deps */

import "./AddCampaign.scss";

import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DoneIcon from "@mui/icons-material/Done";
import Loader from "../../utils/loader/Loader";
import MenuItem from "@mui/material/MenuItem";
import RemoveIcon from "@mui/icons-material/Remove";
import { useConfirmDialog } from "../../components/Dialog/ConfirmDialogContext";

//import SMSPreviewDrawer from "./SMSPreviewDrawer";

type TransformedObject = {
  Name: string;
  Link: string;
};

const SMS_EmailCampaign = () => {
  const navigate = useNavigate();
  const { doApi } = useApi();
  const location = useLocation();
  const data = location.state;
  const theme = useTheme();
  const { showConfirmDialog } = useConfirmDialog();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getActionParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("action");
  };
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignActivites, setCampaignActivites] = useState<any>([]);
  const campaignActivity = useRef(true);
  const [editingActivity, setEditingActivity] = useState({
    name: "",
    daysLater: "1",
    type: "EMAIL",
  });
  const [dataActivity, setDataActivity] = useState<any>({});
  useEffect(() => {
    if (campaignActivites.length > 0) {
      setDataActivity(campaignActivites[0]);
    }
  }, [campaignActivites]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<string[]>(() => {
    if (!data?.campaign || typeof getActionParams !== "function") {
      return [];
    }
    if (
      getActionParams() === "update" &&
      data.campaign.Activities !== undefined &&
      data.campaign.Activities.length > 0
    ) {
      return data.campaign.Activities.map((activity: any) => activity.UniqueId);
    }
    return [];
  });

  useEffect(() => {
    if (campaignActivites !== undefined && campaignActivites.length > 0) {
      const Ids = campaignActivites.map((activity: any) => activity.UniqueId);
      setSelectedActivities(Ids);
    }
  }, [campaignActivites]);

  const [campaignData, setcampaignData] = useState<TransformedObject>({
    Name: getActionParams() === "update" ? data.campaign.Name : "",
    Link: "SMS%2BEMAIL",
  });
  const [mailSendingOption, setMailSendingOption] = useState("anytime");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const transformTimeSlots = (sendAtTime: any) => {
    return (
      sendAtTime?.map((slot: any) => ({
        id: Date.now(),
        start: slot.StartTime,
        end: slot.EndTime,
      })) || [{ id: Date.now(), start: "10:00", end: "12:00" }]
    );
  };
  const [timeSlots, setTimeSlots] = useState<any>([]);

  const [reviewLinkOption, setReviewLinkOption] = useState<string>("");
  const [reviewLinkUrl, setReviewLinkUrl] = useState<string>("");
  const isDisabled = mailSendingOption === "anytime";
  // const [Draweropen, setDrawerOpen] = React.useState(false);
  // const [clkPreview, setClkPreview] = useState("");
  const [autoTrigger, setAutoTrigger] = useState(data?.IsAutoTriggerEnabled);
  // State for toggle switch
  // const [isDefaultCampaign, setIsDefaultCampaign] = useState(data.campaign !== undefined ? data.campaign?.IsDefault: false);

  // State for radio buttons
  const [campaignStartOption, setCampaignStartOption] = useState(
    data.campaign !== undefined && !data.campaign?.IsTriggerManually
      ? "autoTrigger"
      : "manualTrigger"
  );

  // Handler for toggle switch
  // const handleDefaultCampaignToggle = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setIsDefaultCampaign(event.target.checked);
  // };

  // Handler for radio buttons
  const handleCampaignStartChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasUnsavedChanges(true);
    if (autoTrigger) {
      showConfirmDialog({
        title: "Confirmation",
        message:
          "Only one auto-trigger campaign can be active at a time. Would you like to change the existing auto-trigger campaign to manual before creating this new one?",
        onConfirm: () => {
          setAutoTrigger(false);
          setCampaignStartOption(event.target.value);
        },
        onCancel: () => {
          console.log("Delete action cancelled!");
        },
      });
    } else {
      setCampaignStartOption(event.target.value);
    }
  };

  // const toggleDrawer = (newOpen: boolean, uniqueId?: string) => () => {
  //   setDrawerOpen(newOpen);
  //   setClkPreview(uniqueId || "");
  // };

  const handleTimeChange = (
    id: number,
    field: "start" | "end",
    value: string
  ) => {
    setHasUnsavedChanges(true);
    setTimeSlots(
      timeSlots.map((slot: any) =>
        slot.id === id ? { ...slot, [field]: value } : slot
      )
    );
  };
  useEffect(() => {
    if (Object.keys(dataActivity).length > 0) {
      setReviewLinkOption(dataActivity?.Link?.Type || "");
      setReviewLinkUrl(dataActivity?.Link?.Url || "");
      setTimeSlots(transformTimeSlots(dataActivity?.Schedule?.SendAtTime));
      setSelectedDays(dataActivity?.Schedule?.SendOnDays || []);
    }
  }, [dataActivity]);

  useEffect(() => {
    if (isEditing) {
      const type = editingActivity.type; // Get current type (EMAIL or SMS)

      // Filter activities based on type
      const filteredActivities = campaignActivites.filter(
        (activity: any) => activity.Mode.toUpperCase() === type.toUpperCase()
      );

      // Extract the last number from the Name property
      const numbers = filteredActivities.map((activity: any) => {
        const match = activity.Name.match(/\d+$/); // Extract number at the end
        return match ? parseInt(match[0], 10) : 0;
      });

      // Find the highest number and increment it
      const nextNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
      setEditingActivity({
        ...editingActivity,
        name: `${editingActivity.type} ${nextNumber}`,
      });
    }
  }, [isEditing, editingActivity.type, campaignActivites]);

  const saveActivity = async (activity: any) => {
    try {
      setLoading(true);
      const createCampaignActivityConfig: IAPIConfig = {
        URL: `/Campaign/CreateCampaignActivity?userUniqueId=${data.usrUniqueId}`,
        method: "POST",
        payLoad: {
          businessId: data.businessId,
          locationId: data.locationId,
          name: activity.name,
          mode: editingActivity.type,
          link: { type: "default", url: "" },
          daysLater: activity.daysLater,
          emailInfo: {
            type: "",
            frequency: "",
            subject: "",
            content: "",
            fullContent: "",
            sendText: "",
          },
          smsInfo: {
            type: "",
            text: "",
            fullContent: "",
          },
          schedule: {
            sendOnDays: [""],
            sendAtTime: [
              {
                startTime: "",
                endTime: "",
              },
            ],
            count: 0,
          },
          timeSlots: {
            startTime: "",
            endTime: "",
          },
        },
      };
      const response: any = await doApi(
        createCampaignActivityConfig,
        "seedcore"
      );
      if (response?.data?.err === false) {
        CustomToast("Activity saved successfully!", "success");
        fetchCampaignActivites(campaignData.Link);
        setEditingActivity({ name: "", daysLater: "1", type: "EMAIL" });
        setIsEditing(false);
      } else {
        CustomToast(response?.data?.message, "error");
      }
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddActivity = () => {
    setIsEditing(true);
  };

  const handleActivityChange = (key: string, value: any) => {
    setEditingActivity({ ...editingActivity, [key]: value });
  };

  const handleClickOutside = () => {
    if (editingActivity) saveActivity(editingActivity);
  };

  const handleDayChange = (day: string) => {
    setHasUnsavedChanges(true);
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };
  // const fetchCampaignSettings = async () => {
  //   setLoading(true);
  //   try {
  //     const fetchCampaignSettingsConfig: IAPIConfig = {
  //       URL: `/Campaign/GetCampaignSettings?userUniqueId=${data.usrUniqueId}&businessId=${data.businessId}&locationId=${data.locationId}`,
  //       method: "GET",
  //     };
  //     const campaignSettings: any = await doApi(
  //       fetchCampaignSettingsConfig,
  //       "seedcore"
  //     );
  //     if (!campaignSettings.data.err) {
  //       const parseData = JSON.parse(campaignSettings.data.data);
  //       setCampaignStartOption(
  //         parseData[0]?.IsTriggerManually ? "manualTrigger" : "contactAdded"
  //       );
  //       setIsDefaultCampaign(parseData[0]?.IsImmediateSchedule);
  //     }
  //   } catch (error: any) {
  //     CustomToast(error?.message, "error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const updateCampaignSettings = async () => {
  //   setLoading(true);
  //   try {
  //     const inputPayload = {
  //       locationId: data.locationId,
  //       defaultCampaignId: data.campaign.UniqueId,
  //       isTriggerManually:
  //         campaignStartOption === "contactAdded" ? false : true,
  //       isImmediateSchedule:
  //         campaignStartOption === "contactAdded" ? true : false,
  //     };
  //     const updateCampaignSettingsConfig: IAPIConfig = {
  //       URL: `/Campaign/UpdateCampaignSettings?userUniqueId=${data.usrUniqueId}&businessId=${data.businessId}`,
  //       method: "POST",
  //       payLoad: inputPayload,
  //     };
  //     await doApi(updateCampaignSettingsConfig, "seedcore");
  //     fetchCampaignSettings();
  //   } catch (error: any) {
  //     CustomToast(error?.message, "error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (data.campaign !== undefined && data.campaign.UniqueId !== "") {
  //     updateCampaignSettings();
  //   }
  // }, [data, campaignStartOption, isDefaultCampaign]);

  // Fetch Campaign activites
  const fetchCampaignActivites = async (mode: string) => {
    setLoading(true);
    try {
      const fetchCampaignActivitesConfig: IAPIConfig = {
        URL: `/Campaign/GetCampaignActivities?userUniqueId=${data.usrUniqueId}&businessId=${data.businessId}&locationId=${data.locationId}&mode=${mode}`,
        method: "POST",
        payLoad: {},
      };
      const campaignActivites: any = await doApi(
        fetchCampaignActivitesConfig,
        "seedcore"
      );
      if (campaignActivites?.data.err === false) {
        const parseData = JSON.parse(campaignActivites?.data?.data);
        setCampaignActivites(parseData);
      } else {
        CustomToast(campaignActivites.data.message, "error");
      }
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Campaign activites
  const createCampaign = async () => {
    if (campaignData?.Name !== "" && getSelectedActivities().length > 0) {
      if (
        getSelectedActivities().some((activity: any) => {
          const emailContent =
            activity.EmailInfo !== undefined
              ? activity.EmailInfo?.FullContent?.trim()
              : true;
          const smsContent =
            activity.SmsInfo !== undefined
              ? activity.SmsInfo?.FullContent?.trim()
              : true;
          return !emailContent && !smsContent;
        })
      ) {
        CustomToast(
          "Some selected activities have empty htmlContent.",
          "error"
        );
        return;
      }

      setLoading(true);

      const selectedActivities = getSelectedActivities();

      const finalActivities = selectedActivities.map((activity: any) => {
        const updatedLink = activity?.Link
          ? {
              Link: {
                Type: reviewLinkOption,
                Url: reviewLinkUrl,
              },
            }
          : {};

        let updatedEmailInfo = {};
        if (activity.Mode === "EMAIL" && activity.EmailInfo?.FullContent) {
          const updatedFullContent = activity.EmailInfo.FullContent.replace(
            /<a\s+href="([^"]+)"([^>]*)>/,
            `<a href="${reviewLinkUrl}"$2>`
          );

          updatedEmailInfo = {
            EmailInfo: {
              ...activity.EmailInfo,
              Frequency: mailSendingOption,
              FullContent: updatedFullContent,
            },
          };
        }

        const updatedSMSInfo =
          activity.Mode === "SMS"
            ? {
                SMSInfo: {
                  ...activity.SMSInfo,
                  Frequency: mailSendingOption,
                },
              }
            : {};

        return {
          ...activity,
          ...updatedLink,
          ...updatedEmailInfo,
          ...updatedSMSInfo,
          Schedule: {
            sendOnDays: selectedDays,
            sendAtTime: timeSlots.map((slot: any) => ({
              startTime: slot.start,
              endTime: slot.end,
            })),
            count: timeSlots.length,
          },
        };
      });

      let payload = {
        userUniqueId: data.usrUniqueId,
        businessId: data.businessId,
        name: campaignData?.Name,
        locationId: data.locationId,
        mode: "SMS&EMAIL",
        campaignId: "",
        noOfActivities: finalActivities.length,
        activities: finalActivities,
        isDefault: false,
        isTriggerManually: campaignStartOption !== "autoTrigger",
        status: "scheduled",
        recipients: [],
        reviewLink: "",
        qrCodeType: "",
        qrCodePrintMsg: "",
        qrCodeMailMsg: "",
        genQRCode: "",
      };
      try {
        let campaignConfig: IAPIConfig;

        if (getActionParams() === "update") {
          payload = { ...payload, campaignId: data?.campaign?.UniqueId };
          campaignConfig = {
            URL: `/Campaign/UpdateCampaign?userUniqueId=${data.usrUniqueId}`,
            method: "POST",
            payLoad: payload,
          };
        } else {
          campaignConfig = {
            URL: `/Campaign/CreateCampaign?userUniqueId=${data.usrUniqueId}`,
            method: "POST",
            payLoad: payload,
          };
        }

        const campaignRes: any = await doApi(campaignConfig, "seedcore");
        if (campaignRes?.data.err === false) {
          CustomToast(campaignRes.data.message, "success");
          if (getActionParams() !== "update") {
            setcampaignData({ Name: "", Link: "SMS%2BEMAIL" });
            navigate(-1);
          }
          return true;
        } else {
          CustomToast(campaignRes.data.message, "error");
          return false;
        }
      } catch (error: any) {
        CustomToast(error?.message, "error");
        return false;
      } finally {
        setLoading(false);
      }
    } else {
      CustomToast("Please enter campaign name or select activity", "error");
    }
  };
  const handleBack = async () => {
    // const res = await createCampaign();
    // if (res) {
    //   navigate(-1);
    // }
    navigate(-1);
  };

  const handleCheckboxChange = (activityId: string) => {
    setHasUnsavedChanges(true);
    setSelectedActivities((prevSelected) =>
      prevSelected.includes(activityId)
        ? prevSelected.filter((id) => id !== activityId)
        : [...prevSelected, activityId]
    );
  };

  // Get selected activities based on IDs
  const getSelectedActivities = () =>
    campaignActivites.filter((activity: any) =>
      selectedActivities.includes(activity.UniqueId)
    );

  useEffect(() => {
    if (
      data.usrUniqueId &&
      data.businessId &&
      data.locationId &&
      campaignActivity.current
    ) {
      fetchCampaignActivites(campaignData.Link);
      campaignActivity.current = false;
    }
  }, [campaignData.Link, data]);

  const handleCancel = () => {
    setIsEditing(false);
    setEditingActivity({ name: "", daysLater: "1", type: "EMAIL" });
  };

  const mailSendingHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailSendingOption(event.target.value);
    setHasUnsavedChanges(true);
  };

  const editMode = (activity: any) => {
    navigate(`/edit-activity-screen`, {
      state: {
        activity: activity,
        userUniqueId: data.usrUniqueId,
        campaignName: campaignData?.Name,
        action: getActionParams(),
        activites: campaignActivites
      },
    });
  };

  const handleCopyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text).then(
      () => console.log("Link copied to clipboard!"),
      (err) => console.error("Failed to copy text: ", err)
    );
  };

  return (
    <Box className="Add-Campaign-Container">
      <Box
        className="campaign-paganation"
        sx={{
          position: "sticky",
          top: 0,
          background: "#f6f8f8",
          zIndex: 1000
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignSelf: "stretch",
            alignItems: "flex-start",
          }}
        >
          <ArrowBackIcon
            sx={{
              marginBottom: "12px",
              color: "rgba(0, 0, 0, 0.56)",
              cursor: "pointer",
            }}
            onClick={() => handleBack()}
          />
          {hasUnsavedChanges && (
            <Alert variant="standard" severity="warning">
              You have unsaved edits
            </Alert>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5" sx={{ marginTop: 3 }}>
            New SMS/Email Campaign
          </Typography>
          <Box sx={{ display: "flex", columnGap: "12px" }}>
            <Button variant="outlined" color="primary" onClick={createCampaign}>
              Save Edits
            </Button>
            {hasUnsavedChanges && (
              <Button
                variant="outlined"
                color="error"
                size="medium"
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate("/reviews");
                }}
              >
                Exit Without saving
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Loader spinning={loading}>
        <Box className="campaign-container">
          <Box className="campaign-card">
            <Typography variant="body1" sx={{ marginTop: 3, marginBottom: 2 }}>
              Campaign Info
            </Typography>
            <TextField
              label="Campaign Name"
              variant="filled"
              sx={{ width: "790px", height: "56px" }}
              fullWidth
              value={campaignData?.Name}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setcampaignData({ ...campaignData, Name: e.target.value });
              }}
            />
            {/* <FormControl
            variant="filled"
            sx={{ minWidth: { xs: "100%", lg: "376px" } }}
          >
            <InputLabel id="CampaignType">Campaign Type</InputLabel>

            <Select
              labelId="CampaignType"
              id="CampaignType"
              value={campaignData?.Link}
              onChange={(e) => {
                setcampaignData({ ...campaignData, Link: e.target.value });
                campaignActivity.current = true;
              }}
              label="Campaign Type"
            >
              <MenuItem value="SMS%2BEMAIL">SMS + EMAIL(default)</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="EMAIL">EMAIL</MenuItem>
            </Select>
          </FormControl> */}
          </Box>
          <Box className="campaign-card">
            <Typography variant="subtitle1" id="reviewlink">
              Review Link
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              sx={{ marginTop: 1 }}
            >
              <FormControl
                variant="outlined"
                sx={{ minWidth: { xs: "100%", sm: "240px" } }}
              >
                <Select
                  value={reviewLinkOption}
                  onChange={(e) => {
                    setHasUnsavedChanges(true);
                    setReviewLinkOption(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="customlink">Custom Link</MenuItem>
                  <MenuItem value="googlebusinesslink">
                    Google Business Link
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                sx={{
                  minWidth: { xs: "100%", sm: "776px" },
                  color: "rgba(0, 0, 0, 0.38)",
                }}
                value={reviewLinkUrl}
                onChange={(e: any) => {
                  setReviewLinkUrl(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyAllIcon
                        color="primary"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleCopyToClipboard(reviewLinkUrl)}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Box>
          <Box className="campaign-card">
            {/* <Typography
              variant="subtitle1"
              id="campaignsettings"
              sx={{ mt: 2 }}
            >
              Campaign Settings
            </Typography> */}
            {/* Toggle Switch */}
            {/* <FormControlLabel
              control={
                <Switch
                  checked={isDefaultCampaign}
                  onChange={handleDefaultCampaignToggle}
                  color="primary"
                />
              }
              label="Make Default Campaign"
            /> */}

            {/* Radio Buttons */}

            <Typography variant="subtitle1">Auto Trigger</Typography>

            <RadioGroup
              value={campaignStartOption}
              onChange={handleCampaignStartChange}
              className="trigger-controls"
            >
              <FormControlLabel
                value="autoTrigger"
                control={<Radio />}
                label="On ( When Contact added)"
              />
              <FormControlLabel
                value="manualTrigger"
                control={<Radio />}
                label="Off (Manual)"
              />
            </RadioGroup>
          </Box>
          <Box className="campaign-card">
            <Typography variant="subtitle1" id="reviewlink">
              Schedule Requests
            </Typography>

            <RadioGroup value={mailSendingOption} onChange={mailSendingHandler}>
              <FormControlLabel
                value="anytime"
                control={<Radio />}
                label="Immediately"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom Schedule"
              />
            </RadioGroup>

            {mailSendingOption === "custom" && (
              <>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
                  >
                    Emails will only go out on selected days and times below
                  </Typography>
                  <ToggleButtonGroup
                    value={selectedDays}
                    exclusive
                    aria-label="Platform"
                    sx={{
                      marginTop: 1,
                      height: "40px",
                      fontWeight: "bold",
                      fontSize: "13px",
                      textTransform: "capitalize",
                      color: "red",
                      flexWrap: isMobile ? "wrap" : "nowrap",
                      justifyContent: isMobile ? "space-evenly" : "center", // More even spacing on mobile
                      gap: isMobile ? 1 : 0,
                      padding: "4px 0",
                    }}
                    size="small"
                    disabled={isDisabled} // Disable the days selection when "Anytime" is selected
                  >
                    {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                      (day) => (
                        <ToggleButton
                          key={day}
                          value={day}
                          selected={
                            Array.isArray(selectedDays) &&
                            selectedDays.length > 0 &&
                            selectedDays.includes(day)
                          }
                          onClick={() => handleDayChange(day)}
                          sx={{
                            textTransform: "capitalize",
                            padding: "0 8px",
                            width: "57.14px",
                            color: "rgba(0, 0, 0, 0.87)",
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                          }}
                        >
                          {day}
                        </ToggleButton>
                      )
                    )}
                  </ToggleButtonGroup>
                </Box>
                <Box>
                  {timeSlots.map((slot: any) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ xs: 1, sm: 2 }}
                      key={slot.id}
                      sx={{ marginTop: { xs: 3, sm: 1 } }}
                    >
                      <TextField
                        type="time"
                        value={slot.start}
                        size="small"
                        sx={{ width: "177.5px" }}
                        onChange={(e) =>
                          handleTimeChange(slot.id, "start", e.target.value)
                        }
                        disabled={isDisabled} // Disable start time field when "Anytime" is selected
                      />
                      <Box>
                        <RemoveIcon
                          sx={{ width: "8px", color: "rgba(0, 0, 0, 0.87)" }}
                        />
                      </Box>
                      <TextField
                        type="time"
                        value={slot.end}
                        size="small"
                        sx={{ width: "177.5px" }}
                        onChange={(e) =>
                          handleTimeChange(slot.id, "end", e.target.value)
                        }
                        disabled={isDisabled} // Disable end time field when "Anytime" is selected
                      />
                    </Stack>
                  ))}
                </Box>
              </>
            )}
          </Box>
          <Box className="campaign-card">
            <Box className="activity-container">
              <Box className="activity-info">
                <Typography variant="body1">Campaign Setup</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Participant will move through the campaign only until review
                  request is complete
                </Typography>
              </Box>
              <Box className="activity-btn">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<AddIcon />}
                  onClick={handleAddActivity}
                >
                  Add Activity
                </Button>
              </Box>
            </Box>
            <Box className="activites">
              {isEditing && (
                <Box
                  className="each-activity"
                  style={{ columnGap: "8px !important" }}
                >
                  {" "}
                  <Typography variant="subtitle1" sx={{ width: "84px" }}>
                    {editingActivity.name}
                  </Typography>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", gap: "12px" }}
                  >
                    <>
                      <FormControl
                        variant="outlined"
                        sx={{ minWidth: { xs: "100%", lg: "220px" } }}
                      >
                        <InputLabel id="CampaignEditType">
                          Message Type
                        </InputLabel>

                        <Select
                          labelId="CampaignEditType"
                          id="CampaignEditType"
                          value={editingActivity.type}
                          variant="outlined"
                          onChange={(e) =>
                            handleActivityChange("type", e.target.value)
                          }
                          size="medium"
                          label="Message Type"
                        >
                          <MenuItem value="SMS">SMS</MenuItem>
                          <MenuItem value="EMAIL">EMAIL</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        label="Days Later"
                        type="number"
                        value={editingActivity.daysLater}
                        onChange={(e) =>
                          handleActivityChange(
                            "daysLater",
                            Math.max(0, Number(e.target.value))
                          )
                        }
                        sx={{ minWidth: 220 }}
                      />
                    </>
                  </Stack>
                  <Box className="icons-group">
                    <IconButton
                      color="primary"
                      sx={{
                        backgroundColor: "#0F4343",
                        color: "#FFFFFF",
                        marginRight: { xs: 1 },
                      }}
                      onClick={() => handleClickOutside()}
                    >
                      <DoneIcon sx={{ width: "1.25rem", height: "1.25rem" }} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      sx={{ border: "1px solid #0F4343" }}
                      onClick={() => handleCancel()}
                    >
                      <CloseIcon sx={{ width: "1.25rem", height: "1.25rem" }} />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {campaignActivites.length > 0 &&
                campaignActivites.map((activity: any, index: number) => (
                  <React.Fragment key={activity.UniqueId}>
                    <Card className="each-activity">
                      <Stack
                        direction="row"
                        gap="8px"
                        sx={{
                          alignItems: "center",
                          color: selectedActivities.includes(activity.UniqueId)
                            ? "#0F4343"
                            : "gray",
                          opacity: selectedActivities.includes(
                            activity.UniqueId
                          )
                            ? 1
                            : 0.5,
                          cursor: "pointer",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleCheckboxChange(activity.UniqueId)
                          }
                        >
                          {selectedActivities.includes(activity.UniqueId) ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>

                        <Box>
                          <Typography variant="subtitle1" color="#171D1A">
                            {activity.Name}
                          </Typography>
                          <Typography variant="body2" sx={{color:"rgba(0, 0, 0, 0.87)"}}>
                              {activity.DaysLater} day(s) later
                          </Typography>
                        </Box>
                      </Stack>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: "center",
                            color: "#0F4343",
                            cursor: "pointer",
                          }}
                        >
                          <ArrowForward
                            sx={{ width: "24px", height: "24px" }}
                            onClick={() => editMode(activity)}
                          />
                        </Stack>
                      </Box>
                    </Card>
                  </React.Fragment>
                ))}
            </Box>
          </Box>
        </Box>
      </Loader>
      {/* <SMSPreviewDrawer
        open={Draweropen}
        toggleDrawer={toggleDrawer}
        data={campaignActivites}
        preview={clkPreview}
        editModeOn={editMode}
      /> */}
    </Box>
  );
};

export default SMS_EmailCampaign;
